import { Paragraph, ParagraphsFactory } from 'components/ParagraphsFactory';

import type { FC } from 'react';
import WithLayout from '../WithLayout';
import { useRef } from 'react';

interface DataProps {
  content: any;
}

const Home: FC<DataProps> = ({ content }) => {
  const paragraphs = content.paragraphs?.map(({ entity }: { entity: Paragraph }) => ({
    type: entity.type,
    content: entity,
  }));

  const firstRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleArrowClick = () => {
    if (!firstRef.current) return;

    firstRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <main role="main" data-node-id={content.id} about={content.url?.path}>
      {paragraphs && (
        <ParagraphsFactory
          paragraphs={paragraphs}
          firstRef={firstRef}
          langcode={content.langcode.value}
        />
      )}
    </main>
  );
};

export default WithLayout(Home);
